import React from 'react';
import styled from 'styled-components';
import Laoder from 'assets/icons/loading-circle.svg';

interface ILoader {}
export const Loader: React.FC<ILoader> = () => (
  <OuterWrapper>
    <LoaderWrapper>
      <Laoder />
    </LoaderWrapper>
  </OuterWrapper>
);

const OuterWrapper = styled.div`
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled(Laoder)`
  height: 52px;
  width: 52px;
`;
