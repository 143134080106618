import React, { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Icon from 'assets/icons/icons.svg';

interface OverlayProps {
  navLinks: { title: string; url: string }[];
  socialLinks: { svgId: string; url: string }[];
  zIndex?: number;
}

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden !important;
  }
`;

const Containter = styled.div<{ zIndex?: number }>`
  display: flex;
  backdrop-filter: blur(1.5rem);
  position: fixed;
  top: 0;
  bottom: 0;
  transition: all 0.2s ease;
  padding: 3rem 1rem;
  overflow: auto;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 99)};
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  display: block;
  .links {
    display: block;
    padding: 2.25rem 0 0.69rem;
    li {
      margin: 0;
      padding: 0 0 1.68rem;
    }
    a {
      display: block;
      padding: 0.63rem;
      text-align: center;
      font-size: 1rem;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }
  .social {
    display: flex;
    margin: 0;
    padding: 0;
    height: 1.5rem;
    justify-content: space-between;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  li {
    margin: 0;
  }
  a {
    display: block;
    padding: 0.63rem;
    text-align: center;
  }

  .stores {
    display: flex;
    flex-wrap: nowrap;
    padding: 3rem 0.4rem 1.5rem;
    justify-content: space-between;

    svg,
    img {
      height: 2.75rem;
    }
    svg {
      width: 9rem;
    }
  }
`;
// TO DO ISTRINT
const Overlay: FC<OverlayProps> = ({ navLinks, socialLinks, zIndex }) => (
  <>
    <GlobalStyle />
    <Containter zIndex={zIndex}>
      <ul className="links">
        {navLinks.map((navLink, index) => (
          <li key={index}>
            <a href={navLink?.url}>{navLink?.title}</a>
          </li>
        ))}
      </ul>
      <ul className="social">
        {socialLinks.map((socialLink, index) => (
          <li key={index}>
            <a href={socialLink?.url} target="_blank" rel="noreferrer">
              <svg>
                <use xlinkHref={`#${socialLink?.svgId}`} />
              </svg>
            </a>
          </li>
        ))}
      </ul>
      <Icon />
    </Containter>
  </>
);

export default Overlay;
