import { useRouter } from 'apis/history';
import * as React from 'react';
import { tablet } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';
import Logo from 'assets/icons/logo-icon.svg';
import Fb from 'assets/icons/quiz/facebook.svg';
import Ig from 'assets/icons/quiz/instagram.svg';
import { useQuizData } from 'utils/hooks';

interface FooterProps {
  disclaimerText: string[];
  whiteFooter?: boolean;
}

const StyledLogo = styled(Logo)`
  width: 1.375rem;
  height: 1.375rem;
`;

const LCFooter: React.FC<FooterProps> = ({
  disclaimerText,
  whiteFooter = false,
}) => {
  const { goToPrivacy, goToTerms, goToContact, goToLanding, goToReviews } =
    useRouter();
  const data = useQuizData('pagesLinks');
  return (
    <>
      <Container whiteFooter={whiteFooter}>
        <Wrapper>
          <Links>
            <LinkWrap onClick={() => goToLanding()}>
              <StyledLogo />
            </LinkWrap>

            {/* <LinkWrap onClick={() => goToReviews()}>
                <StyledTextT>Reviews</StyledTextT>
              </LinkWrap> */}
            <LinkWrap>
              <StyledTextT type="bodyM600" color="light0">
                <StyledLink href="https://help.nordpilates.com/hc/en-us">
                  {data?.helpCenter || 'Help Center'}
                </StyledLink>
              </StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToContact()}>
              <StyledTextT>{data?.contact || 'Contact Us'}</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT>{data?.terms || 'Terms & Conditions'}</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT>{data?.privacy || 'Privacy Policy'}</StyledTextT>
            </LinkWrap>
          </Links>

          <DisclaimerContainer>
            <Wrap>
              <IconsContainer>
                <a
                  href="https://www.facebook.com/profile.php?id=61570040494869"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Fb />
                </a>
                <a
                  href="https://www.instagram.com/nordpilates.com.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Ig />
                </a>
              </IconsContainer>
              {disclaimerText.map((paragraph, index) => (
                <StyledText key={index}>{paragraph}</StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default LCFooter;

const IconsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1.5rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.75rem;
  }
`;

const Dot = styled.div`
  color: #fff;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisclaimerWrap = styled.div`
  cursor: pointer;
`;

const StyledTextT = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
`;

const StyledLink = styled.a`
  color: #000 !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  gap: 1.5rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledText = styled(Text)`
  color: #777;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
`;

const StyledTextBold = styled(StyledTextT)`
  font-weight: 600 !important;
`;

const DisclaimerContainer = styled.div`
  width: 100%;
  border-top: 1px solid rgba(119, 119, 119, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    gap: 0.5rem;
  }
`;

const Container = styled.div<{ whiteFooter?: boolean }>`
  display: flex;
  padding: 1.5rem 0;
  gap: 1.5rem;
  background: ${({ whiteFooter }) => (whiteFooter ? '#fff' : '#F5F5F5')};
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 1.75rem 1rem;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media ${tablet} {
    flex-direction: column;
    height: unset;
    gap: 1rem;
    width: 100%;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${tablet} {
  }
`;
