import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { mobile, tablet, useQuery } from 'styles/breakpoints';

interface HeadlineProps {
  title: string;
  subtitle: string;
}

const Container = styled.div`
  padding: 4rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: #f9f9f9;
  @media ${tablet} {
    padding: 2.25rem 1rem 0;
    gap: 0.5rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;

  @media ${tablet} {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
  }
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  max-width: 48rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const Headline: FC<HeadlineProps> = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

export default React.memo(Headline);
