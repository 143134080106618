import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Api } from '../../apis/api';
import { handleError, is404Error } from '../../utils/error';
import {
  setExperiments,
  setNextRoute,
  setProducts,
  setQuestions,
} from './actions';
import { IExperiments } from './types';
import { Quiz } from 'types/quiz';

import temp from './temp.json';

export const fetchProducts =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.fetchProducts()
      .then(products => dispatch(setProducts(products)))
      .catch(handleError);

export const fetchQuiz =
  (variant?: string) =>
  async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    try {
      const questions = await Api.fetchQuiz(variant);
      dispatch(setQuestions(temp));
    } catch (error) {
      handleError(error as Error);
      if (is404Error(error)) {
        const questions = await Api.fetchQuiz(Quiz.Main);
        dispatch(setQuestions(temp));
      }
    }
  };

export const updateExperiments =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    let experiments: IExperiments = {};

    if (typeof window !== `undefined`) {
      const params = new URLSearchParams(window.location?.search);
      const testId = params.get('test');
      const flow = params.get('flow');

      if (testId) {
        experiments[testId] = true;
      }

      if (flow) {
        experiments[flow] = true;
      }
    }

    dispatch(setExperiments(experiments));
  };

export const updateNextRoute =
  (nextRoute: string) => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    dispatch(setNextRoute(nextRoute));
  };
