import { Color } from 'utils/theme';

export enum Quiz {
  Main = 'main-np',
}

export enum QuizKey {
  Qz = 'qz',
}

export interface QuizAnswer {
  [key: string]: string | boolean | number | object;
}

export interface QuestionOption {
  label: string;
  value: string;
  image?: string;
  subtitle?: string;
  emoji?: string;
  smallEmoji?: boolean;
  score: string;
  color?: Color;
  custom: {
    icon: string;
    deselectAll?: boolean;
    horizontal?: boolean;
    disabled?: string;
  };
}

interface Image {
  desktopImg?: string;
  mobileImg?: string;
  alt?: string;
}

export type Question = {
  key: string;
  label: string;
  optionsLabel?: string;
  disclaimerParagraphs?: string[];
  disclaimerParagraphsPR?: string[];
  female?: string;
  male?: string;
  hideProgress?: boolean;
  hideBackButton?: boolean;
  options?: QuestionOption[];
  optionsMale?: QuestionOption[];

  labelFemale?: string;
  labelMale?: string;
  subtitle?: string;
  subtitles?: string[];
  imgFemale?: string;
  imgMale?: string;
  imgFemaleMob?: string;
  imgMaleMob?: string;

  title?: string;
  mobileImg?: string;
  image?: string;
  alt: string;
  mdpiImage?: Image;
  description?: string;
  descriptionIsMaxWidth?: boolean;
  paragraphs?: string[];
  navLinks?: { url: string; title: string }[];
  socialLinks?: { svgId: string; url: string }[];

  accept?: Array<string>;
  bmiHeading?: { [key: string]: string };
  bmiParagraph?: { [key: string]: string };
} & Record<string, string>;
