import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Question, Quiz, QuizAnswer, QuizKey } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import LCFooter from 'components/LCFooter';
import { useScrollToTop } from 'utils/hooks';
import Arrow from 'assets/icons/arrow-right.svg';
import Header from 'components/reviews/Header';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
}
const easing = 'cubic-bezier(0.42, 0, 0.58, 1)';
const smallScroll = keyframes`
  from {
    transform: translateY(150px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const bigScroll = keyframes`
  from {
    transform: translateY( 200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  margin-bottom: 0.5rem;
  span {
    display: block;
  }
  @media ${tablet} {
    font-size: 2rem;
    line-height: 2.375rem;
  }
`;

const Subtitle = styled(Text)`
  color: #777;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-bottom: 1.5rem;
  @media ${tablet} {
    padding-bottom: 1rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  row-gap: 1.5rem;
  width: 100%;
  animation: ${bigScroll} 600ms ${easing}0s, ${show} 1s ${easing} 0s backwards;
  @media ${tablet} {
    gap: 1rem;
    row-gap: 1rem;
    max-width: 388px;
  }
`;

const Button = styled.div<{ active?: boolean }>`
  border-radius: 0.625rem;
  border: none;
  background: #ebebeb;
  max-width: 14rem;
  height: 15.25rem;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media ${tablet} {
    max-height: 196px;
    max-width: 186px;
  }
  /* @media{
    height: 11.125rem;
  } */
`;

const ButtonText = styled(Text)`
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  background: #f27999;
  height: 2.75rem;
  max-width: 14rem;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 0.75rem 0.5rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.375rem;
  position: absolute;
  svg {
    width: 1.53656rem;
    height: 1.53656rem;
  }
  bottom: 0;

  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    height: 2.5rem;
    padding: 0.625rem 0.5rem;
    max-width: 243px;
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

const StyledDynamicImage = styled(DynamicImage)<{ isScaled: boolean }>`
  width: 97%;
  position: absolute;
  right: 50%;
  transform: translateX(50%) scale(${({ isScaled }) => (isScaled ? 1.1 : 1)});
  transition: transform 0.6s ease;
  pointer-events: none;
  bottom: 2.5rem;

  @media ${tablet} {
    max-width: 243px;
    max-height: 150px;
  }
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState<number | null>(
    null,
  );
  const [clickedButtonIndex, setClickedButtonIndex] = useState<number | null>(
    null,
  );
  const [active, setActive] = useState<string | null>(null);
  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;
  const isAddsFlow = location.pathname.includes('start') || quiz !== Quiz.Main;

  useScrollToTop(0);

  const handleOptionClick = (value: string, label: string, index: number) => {
    setActive(value);
    setClickedButtonIndex(index); // Set clicked state
    setTimeout(() => {
      onOptionSelect({
        [question?.key]: value,
        label: label,
        age_range: 'y_18_29',
      });
      setClickedButtonIndex(null); // Reset clicked state after animation
    }, 300); // Adjust timeout to match scale duration if needed
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);
  return (
    <>
      <>
        <Header isMobileApp={isAddsFlow} isSticky={!question?.bannerTexts} />
        {/* {question?.bannerTexts && (
          <LoopingBanner
            isNotSticky={false}
            bannerTexts={question?.bannerTexts.texts}
            primaryTextColor={question?.bannerTexts.primaryColor}
            secondaryTextColor={question?.bannerTexts.secondaryColor}
            primaryGradient={question?.bannerTexts.primaryGradient}
            backgroundColor={question?.bannerTexts.backgroundColor}
          />
        )} */}
        <ContentContainer isFullHeight={isAddsFlow}>
          <InnerContainer>
            <TopContainer>
              <Title dangerouslySetInnerHTML={{ __html: question?.label }} />
              <Subtitle>{question?.optionsLabel}</Subtitle>
            </TopContainer>
            <ButtonsContainer>
              {question.options?.map((option, index) => (
                <Button
                  key={index}
                  data-qa={index}
                  onClick={() =>
                    handleOptionClick(option?.value, option?.label, index)
                  }
                  onMouseEnter={() => setHoveredButtonIndex(index)}
                  onMouseLeave={() => setHoveredButtonIndex(null)}
                  active={option.value === active}
                >
                  <StyledDynamicImage
                    src={option?.image as string}
                    alt=""
                    isScaled={
                      hoveredButtonIndex === index ||
                      clickedButtonIndex === index
                    } // Scale on hover or click
                  />
                  <ButtonText>
                    {option.label} <Arrow />
                  </ButtonText>
                </Button>
              ))}
            </ButtonsContainer>
          </InnerContainer>
        </ContentContainer>
        <Footers>
          {isAddsFlow ? (
            <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
          ) : (
            <LCFooter disclaimerText={question?.disclaimerParagraphs} />
          )}
        </Footers>
      </>
    </>
  );
};

export default Landing;
const Footers = styled.section`
  animation: ${bigScroll} 600ms ease-in 0s, ${show} 1s ease-in 0s backwards;
`;

const TopContainer = styled.section`
  display: flex;
  flex-direction: column;
  animation: ${smallScroll} 600ms ease-out 0s, ${show} 1s ease-out 0s;
`;

const ContentContainer = styled.div<{ isFullHeight: boolean }>`
  min-height: ${({ isFullHeight }) =>
    isFullHeight ? ' calc(100vh - 4.5rem)' : 'calc(100vh - 225px)'};
  @media ${tablet} {
    ${({ isFullHeight }) => isFullHeight && 'min-height: calc(100vh - 4rem);'}
  }
`;

const BottomTerms = styled(Text)`
  color: rgba(119, 119, 119, 0.6);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  padding-top: 2rem;
`;

const Highlight = styled.span`
  color: #777;
  color: rgba(119, 119, 119, 0.6);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  cursor: pointer;
`;
