import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  name?: string;
  title?: string;
  description?: string;
}

export const Seo: React.FC<Props> = ({
  name = '#1 Pilates Plan for Weight Loss | Nord Pilates',
  title = '#1 Pilates Plan for Weight Loss | Nord Pilates',
  description = 'The all-in-one pilates plan designed for easy and effective weight loss. Get fit with Nord Pilates.',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={location.toString()} />

    {/* FB tags */}
    <meta property="og:url" content="https://nordpilates.com" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
