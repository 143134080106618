import React, { FC, useState } from 'react';
import styled from 'styled-components';

import Header from 'components/Header';
import { Question, Quiz, QuizKey } from 'types/quiz';
import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import Overlay from './Overlay';
import { useRouter } from 'apis/history';
import LCFooter from 'components/LCFooter';

interface LandingLayoutProps {
  question: Question;
}

const MainContainer = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.light0};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-items: flex-start;
  min-height: calc(100vh - 225px);
  @media ${mobile} {
    flex-direction: column;
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
`;

const LandingLayout: FC<LandingLayoutProps> = ({ question, children }) => {
  const [show, setShow] = useState<boolean>(false);
  const { isMobile } = useQuery();
  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;
  // TO DO ISTRINT
  return (
    <>
      <MainContainer className={show ? 'active-menu' : ''}>
        {quiz === Quiz.Main ? (
          <>
            <div>
              <Header
                logoVariant="center"
                sticky={false}
                // RightComponent={renderRightComponent()}
                color="light0"
                hasDivider={true}
              />
              <ContentContainer>{children}</ContentContainer>
            </div>
          </>
        ) : (
          <>
            <div>
              <Header
                logoVariant="center"
                sticky={false}
                // RightComponent={renderRightComponent()}
                color="light0"
                hasDivider={true}
              />
              <ContentContainer>{children}</ContentContainer>
            </div>
          </>
        )}
      </MainContainer>
      {show && (
        <Overlay
          navLinks={question?.navLinks ?? []}
          socialLinks={question?.socialLinks ?? []}
        />
      )}
    </>
  );
};

export default LandingLayout;
